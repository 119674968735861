<template style="background-color: #343790;">
  <v-app class="app" >
    
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.v-application p {
    margin-bottom: 0px!important;
}
/* .v-application span {
    margin: 0px!important;
    padding: 0px!important;
} */
.v-btn {
  text-transform: none;
}
.app{
 background-color: #343790;
}
</style>