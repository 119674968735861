<template>
  <v-app style="background-color: #343790;">
    <v-row>
      <v-col cols="6">
        <v-row class="mt-5 ml-5">
        <img src="@/assets/logo1.svg"/>
        </v-row> 
   
          <v-row dense class="mt-15">
           <img src="@/assets/front image1.svg" style="height:auto;width:100%;cursor:pointer;" @click="manualLogin()">
          </v-row>
               
      </v-col>
     
      <v-col >
        <v-row>
       <v-spacer></v-spacer>
        <v-btn color="#DD2977" @click="help()" class="mt-10 ml-2 mr-10" style="color:white;font-family: Roboto;font-style: normal;font-weight: 500;font-size:16px;">
          Help ?
        </v-btn>
        </v-row>
        <v-row>
          <img src="@/assets/temp.svg" style="height:auto;width:100%;" class="mt-10" >
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar class="red--text" :color="snack_color" top v-model="snack"
        ><v-icon :color="snack_textcolor" class="mr-2 float-left">{{
          snack_icon
        }}</v-icon
        ><span :style="`color: ${snack_textcolor};font-size: 16px;`">{{
          snack_text
        }}</span
        ><v-icon
          @click="snack = !snack"
          :color="snack_textcolor"
          class="ml-2 mr-2 float-right"
          >mdi-close</v-icon
        ></v-snackbar
      >
  </v-app>
</template>

<script>
// import { GoogleLogin } from "vue-google-login";
import Vue from "vue";
import MyPdf from '../assets/help/Help.pdf';
export default {
  data(){
    return{
        snack_text: "",
        snack: false,
        snack_color: "",
        snack_textcolor: "",
        snack_icon: "",
       params: {
        client_id:
          "275786793332-1tqp5q8gg8en0cleq55gvfbjd4jp144o.apps.googleusercontent.com",
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    }
  },
  //  components: {
  //   GoogleLogin,
  // },
  created(){
  
    this.$store.state.isLoggedIn = false;
    this.$store.state.LoggedInUser = {};
    this.$store.state.Firstname=''
    this.$store.state.Lastname=''
    this.$store.state.Emailid=''
  },
  computed: {
    isLoggedIn() {
      if (this.$store.state.isLoggedIn) {
        return true;
      }
      return false;
    },
  },
  methods:{
    help(){
       var axios = require("axios");
       axios({
        url: MyPdf,
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "help.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    manualLogin() {
      Vue.GoogleAuth.then((auth) => {
        auth.signIn().then(
          guser=>{
            // this.$store.state.isLoggedIn = guser.isLoggedIn.get();
            console.log("mannual login")
            console.log(guser)
            
            this.$store.state.isLoggedIn =true;
            this.$store.state.LoggedInUser = guser;
            this.$store.state.Firstname=guser.getBasicProfile().getGivenName()
            this.$store.state.Lastname=guser.getBasicProfile().getFamilyName()
            this.$store.state.Emailid=guser.getBasicProfile().getEmail()
           
            console.log("basic profile")
            console.log(guser.getBasicProfile().getEmail())
            console.log(guser.getBasicProfile().getGivenName())
            console.log(guser.getBasicProfile().getFamilyName())
            
            if(guser.getBasicProfile()){
             this.$router.push({
               name: "Signature"})
            }
          })
         .catch((error) => {
           console.log(error)
            this.snack = true;
            this.snack_color = "#581030";
            this.snack_textcolor = "#F44336";
            this.snack_text = "Invalid User";
            this.snack_icon = "mdi-alert-circle-outline";
           })
      })
      .catch((error) => {alert(error)})
    },
    
  },
 
 mounted: function() {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = 'hidden'
  },
  destroyed: function() {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = null
  }
}
</script>

<style>
.v-btn {
  text-transform: none!important;
}
.hexagons{
   object-fit: cover;
    overflow: hidden!important;
    scrollbar-width: none;
}
.buildertxt{
  font-family: roboto;
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 54px;

color: #FFFFFF;
}
.email{
  font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;

color: #FFFFFF;
}
/* ::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #10102B; 
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #D6D7E9; 
  border-radius: 2px;
max-height: 50px!important;
}

::-webkit-scrollbar-thumb:hover {
  background: #D6D7E9; 
} */
.theme--light.v-application {
    background: rgb(52, 55, 144)!important;
    color: rgba(0, 0, 0, 0.87);
}
</style>