<template>
  <FrontPage />
</template>

<script>
import FrontPage from "@/components/FrontPage.vue";
  export default {
    name: 'Home',

    components: {
    
        FrontPage,
    },
  }
</script>
