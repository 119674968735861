import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false, 
    LoggedInUser:{},
    Firstname:'',
    Lastname:'',
    Emailid:''
    //  imgurl:''
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
