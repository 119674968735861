import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { LoaderPlugin } from 'vue-google-login';
import store from './store'

Vue.config.productionTip = false;
Vue.use(LoaderPlugin, {
  client_id: "275786793332-1tqp5q8gg8en0cleq55gvfbjd4jp144o.apps.googleusercontent.com"
});
Vue.GoogleAuth.then(auth2 => {
  
    console.log("mainjs")
    console.log(auth2.isSignedIn.get());
    console.log(auth2.currentUser.get())
    var profile=auth2.currentUser.get().getBasicProfile()
    
    store.state.isLoggedIn = auth2.isSignedIn.get()
    store.state.LoggedInUser = auth2.currentUser.get()
    store.state.Firstname=profile.getGivenName()
    store.state.Lastname=profile.getFamilyName()
    store.state.Emailid=profile.getEmail()
    
    if(store.state.isLoggedIn==false){
    router.push({
           name: 'Home',
       });
   }
})




new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
